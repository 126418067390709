.embla {
  /* max-width: 48rem; */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center content vertically */
  position: relative; /* Needed for absolute positioning of child elements */
  margin: auto;
  /* height: 100%; Ensure it fills the container height */
  width: 100%; /* Ensure it spans the full width */
  margin: auto;
  --slide-height: auto;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  height: var(--slide-height);
  /* align-items: center; */
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}

.embla__dots {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-right: calc((2.3rem - 1.1rem) / 2 * -1);
  z-index: 10;
  /* position: absolute; */
  align-items: center;
}

.embla__controls {
  position: absolute;
  bottom: 1rem; /* Adjust the distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Correct the alignment to be fully centered */
  display: grid;
  align-items: center;
  justify-content: center; /* Center the items inside the grid */
  gap: 1.2rem;
  z-index: 10; /* Ensure it appears on top of other elements */
}

.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
  background-color: #efefef;
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: #3d6fa8;
}
