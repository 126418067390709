@media screen and (max-width: 480px) {
  .profile-story-block .content-paragraph h1 {
    font-size: medium;
    font-weight: 700;
  }

  .profile-story-block .content-paragraph p {
    font-size: 13px;
  }
}

@media screen and (min-width: 480px) {
  .profile-story-block .content-paragraph h1 {
    font-size: large;
    font-weight: 700;
  }

  .profile-story-block .content-paragraph p {
    font-size: 14px;
  }
}

@media screen and (min-width: 640px) {
  .profile-story-block .content-paragraph h1 {
    font-size: x-large;
    font-weight: 700;
  }

  .profile-story-block .content-paragraph p {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .profile-story-block .content-paragraph h1 {
    font-size: x-large;
    font-weight: 700;
  }

  .profile-story-block .content-paragraph p {
    font-size: 18px;
  }
}

.profile-story-block {
  background-color: #33557b;
  background-size: cover;
  /* Ensures the image covers the entire div */
  background-position: center;
  /* Centers the image in the div */
  width: 100%;
  /* Set the width of the div */
  min-height: 200px;
  /* Set the height of the div */
  height: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.profile-story-block .content-paragraph {
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-story-block .image-wrapper {
  /* width: 40%; */
  height: auto;
  /* Make the image-wrapper take up to 60% of the profile-story-block height */
  /* overflow: hidden; Ensure the image doesn't overflow the wrapper */
  display: flex;
  align-content: center;
  justify-content: flex-end;
}

.profile-story-block .image-wrapper img {
  max-height: 100%;
  /* Ensure the image doesn't exceed the height of the wrapper */
  width: auto;
  /* Keep the aspect ratio by letting width adjust automatically */
  height: auto;
  /* Maintain the original aspect ratio */
  max-width: 100%;
  float: right;
}