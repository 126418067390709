.cart-modal-content {
  background: #8797a4 !important;
  color: floralwhite !important;
}

@media screen and (max-width: 480px) {
  .cart-modal {
    width: 21rem !important;
  }
}

.cart-item {
  display: flex;
  column-gap: 25px;
}

.cart-item .product-image {
  object-fit: fill;
  min-width: 80px;
  max-width: 80px;
  height: 100px;
  border-radius: 8px;
  margin-top: 15px;
  filter: drop-shadow(0px 2px 3px grey);
}

.cart-item .text-l-font-bold {
  font-size: large;
  font-weight: bold;
  letter-spacing: 1px;
}

.cart-item .text-600-small {
  font-size: small;
  font-weight: 600;
}

.cart-item .item-details {
  font-size: medium;
  font-weight: 450;
  opacity: 80%;
  letter-spacing: 1px;
}

.inquire-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.inquire-btn:after {
  content: '>';
  position: absolute;
  opacity: 0;
  top: 14px;
  right: -20px;
  transition: 0.5s;
}

.inquire-btn:hover {
  padding-right: 24px;
  padding-left: 8px;
}

.inquire-btn:hover:after {
  opacity: 1;
  right: 10px;
}

.remove-btn {
  color: lightgray !important;
  font-size: smaller !important;
  align-self: center;
  letter-spacing: 1px;
}