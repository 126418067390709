@media screen and (max-width: 480px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #eef4fa;
    height: 100px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-logo {
    height: 100%;
    width: 150px !important;
  }

  /* Footer */
  .footer {
    background-color: #33557b;
    color: white;
    text-align: center;
    padding: 0.5rem;
    margin-top: auto;
  }

  .mobile-logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-logo-box .footer-logo {
    height: 80px;
  }

  .mobile-company-info-box {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
  }

  .mobile-heading-block {
    text-align: left;
    color: white;
  }

  .mobile-nav-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: white;
    font-size: 12px;
    font-weight: 500;
  }

  .mobile-nav-block Button {
    font-size: 12px;
    word-spacing: 1px;
    letter-spacing: 1px;
  }

  .mobile-social-block {
    margin-top: 15px;
    text-align: left;
  }

  .mobile-social-block a {
    color: white;
    font-size: 26px;
    margin-right: 15px;
  }
}
@media screen and (min-width: 480px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #eef4fa;
    height: 100px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-logo {
    height: 100%;
    width: 150px !important;
  }

  /* Footer */
  .footer {
    background-color: #33557b;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-top: auto;
  }

  .mobile-logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-logo-box .footer-logo {
    height: 80px;
  }

  .mobile-company-info-box {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
  }

  .mobile-heading-block {
    text-align: left;
    color: white;
  }

  .mobile-nav-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: white;
    font-size: 12px;
    font-weight: 500;
  }

  .mobile-nav-block Button {
    font-size: 12px;
    word-spacing: 1px;
    letter-spacing: 1px;
  }

  .mobile-social-block {
    margin-top: 15px;
    text-align: left;
  }

  .mobile-social-block a {
    color: white;
    font-size: 26px;
    margin-right: 15px;
  }
}

@media screen and (min-width: 640px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #eef4fa;
    height: 100px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-logo {
    height: 100%;
    width: 150px !important;
  }

  /* Footer */
  .footer {
    background-color: #33557b;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-top: auto;
  }

  .mobile-logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-logo-box .footer-logo {
    height: 80px;
  }

  .mobile-company-info-box {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
  }

  .mobile-heading-block {
    text-align: left;
    color: white;
  }

  .mobile-nav-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: white;
    font-size: 14px;
    font-weight: 500;
  }

  .mobile-nav-block Button {
    font-size: 14px;
    word-spacing: 1px;
    letter-spacing: 1px;
  }

  .mobile-social-block {
    margin-top: 15px;
    text-align: left;
  }

  .mobile-social-block a {
    color: white;
    font-size: 26px;
    margin-right: 15px;
  }
}
@media screen and (min-width: 992px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #eef4fa;
    height: 100px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-logo {
    height: 100%;
    width: 150px !important;
  }

  /* Footer */
  .footer {
    background-color: #33557b;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-top: auto;
  }

  .footer-logo {
    height: 80px;
  }

  .footer-box {
    height: auto;
    text-align: left;
    padding: 2vw 3vw 2vw 3vw;
  }
  .footer-box .footer-logo {
    height: 80px;
  }
  .footer-box .company-info-block {
    margin-top: 40px;
  }
  .footer-box .operating-hour-block {
    margin-top: 15px;
  }
  .footer-box .heading-block {
    height: 70px;
    display: flex;
    align-items: flex-end;
  }
  .footer-box .social-block {
    margin-top: 15px;
  }
  .footer-box.social-block a {
    color: white;
    font-size: 2rem;
    margin-right: 15px;
  }
  .footer-box .nav-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
  }
  .footer-box .nav-block Button {
    height: 35px;
    font-size: 14px;
    word-spacing: 2px;
    letter-spacing: 1px;
  }
  .footer-box .contact-block {
    margin-top: 15px;
  }
}
@media screen and (min-width: 1024px) {
  /* big landscape tablets, laptops, and desktops */
  .header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #eef4fa;
    height: 130px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-logo {
    height: 100%;
    width: 190px !important;
  }

  /* Footer */
  .footer {
    background-color: #33557b;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-top: auto;
  }

  .footer-box {
    height: auto;
    text-align: left;
    padding: 2vw 3vw 2vw 3vw;
  }
  .footer-box .footer-logo {
    height: 80px;
  }
  .footer-box .company-info-block {
    margin-top: 40px;
  }
  .footer-box .operating-hour-block {
    margin-top: 15px;
  }
  .footer-box .heading-block {
    height: 70px;
    display: flex;
    align-items: flex-end;
  }
  .footer-box .social-block {
    margin-top: 15px;
  }
  .footer-box .social-block a {
    color: white;
    font-size: 2rem;
    margin-right: 15px;
  }
  .footer-box .nav-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
  }
  .footer-box .nav-block Button {
    height: 30px;
    font-size: 14px;
    word-spacing: 1px;
    letter-spacing: 1px;
  }
  .footer-box .contact-block {
    margin-top: 15px;
  }
}
@media screen and (min-width: 1280px) {
  /* hi-res laptops and desktops */
  /* Header */
  .header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #eef4fa;
    height: 130px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button-text {
    font-size: 20px;
  }

  /* Footer */
  .footer {
    background-color: #33557b;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-top: auto;
    min-height: 300px;
  }

  .footer-box {
    height: 250px;
    text-align: left;
    padding: 0 3vw;
  }

  .footer-box .footer-logo {
    height: 80px;
  }

  .footer-box .company-info-block {
    margin-top: 40px;
  }

  .footer-box .operating-hour-block {
    margin-top: 15px;
  }

  .footer-box .heading-block {
    height: 70px;
    display: flex;
    align-items: flex-end;
  }

  .footer-box .social-block {
    margin-top: 15px;
  }

  .footer-box .social-block a {
    color: white;
    font-size: 2rem;
    margin-right: 15px;
  }

  .footer-box .nav-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
  }

  .footer-box .nav-block Button {
    height: 30px;
    font-size: 14px;
    word-spacing: 1px;
    letter-spacing: 1px;
  }

  .footer-box .contact-block {
    margin-top: 15px;
  }

}

.header-logo {
  height: 100%;
  width: 230px;
}

.header-logo:hover {
  cursor: pointer;
}

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/* Keep layout center-align when screen zoomed in*/
@media screen and (min-width: 2000px) {
  .layout {
    width: 100vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.menu-stack .chakra-button {
  color: #3d4781;
  font-weight: 600;
  letter-spacing: 1px;
}

.mobile-menu-button {
  justify-content: start !important;
  text-align: left;
}

.mobile-menu-title {
  color: #3d4781;
  font-weight: 600;
  letter-spacing: 1px;
}

.logo img {
  height: 50px;
}

/* Navigation Menu */
.nav-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu ul li {
  margin: 0 15px;
  position: relative;
}

.nav-menu ul li a {
  color: white;
  text-decoration: none;
}

.dropdown-menu,
.sub-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #444;
  padding: 10px;
}

.dropdown-menu li {
  margin: 0;
}

.dropdown:hover .dropdown-menu,
.sub-dropdown-menu {
  display: block;
}

/* Social Icons */
.social-icons a {
  color: white;
  margin-left: 15px;
  font-size: 1.2rem;
}

.social-icons a:hover {
  color: #ddd;
}

.whatsapp-fab {
  position: fixed !important;
  bottom: 50px;
  right: 50px;
  height: 75px !important;
  min-width: 75px !important;
  background-color: #25d366 !important;
  color: #fff !important;
  border-radius: 50% !important;
  cursor: pointer;
  /* Add hover effects or animations as desired */
}

.whatsapp-fab:hover {
  background-color: #1e4d2b !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-menu ul {
    flex-direction: column;
    display: none;
  }

  .nav-menu ul.active {
    display: flex;
  }

  .social-icons {
    display: none;
  }

  .whatsapp-fab {
    position: fixed !important;
    bottom: 10px;
    right: 10px;
    height: 60px !important;
    min-width: 60px !important;
  }

  .whatsapp-fab-icon {
    font-size: 2.5em;
  }
}